import React from 'react';
import {createContext, useContext, useState} from 'react';
import axios from 'axios';
import {useForm} from 'react-hook-form';
import { navigate } from 'gatsby';
import clsx from 'clsx';

// Create the context
const FormContext = createContext();

// Create a custom hook to use the context
export const useFormContext = () => useContext(FormContext);

const Form = ({ className, children, thankYouURL, endpoint}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: {errors},
  } = useForm();

  const phoneFormat = (value) => {
    let numbers = value.replace(/^\D+/g, '');
    if(numbers.length === 11) {
      return `+${numbers}`;
    } else if(numbers.length === 10) {
      return `+1${numbers}`;
    }
  };

  const onSubmit = async (data) => {
    data.phone = data.phone && phoneFormat(data.phone);

    try {
      const response = await axios.post(endpoint, data);
      if(response.data.success){
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  if (isSubmitted) {
    return navigate(thankYouURL);
  }
  // Pass the control and errors to the child components through React Context
  return (
    <FormContext.Provider value={{control, errors, register}}>
      <form onSubmit={handleSubmit(onSubmit)} className={ clsx(className, 'bg-white') }>
        { children }
      </form>
    </FormContext.Provider>
  );
};

export default Form;
