import React from 'react';

import Layout from '../../components/Layout';
import GlobalWrapper from '../../components/GlobalWrapper/GlobalWrapper';
import Hero from '../../components/Hero/Hero';
import Title from '../../components/Title/Title';
import Form from '../../components/Form/Form';
import InputField from '../../components/Form/Fields/InputField/InputField';
import CheckboxField from '../../components/Form/Fields/CheckboxField/CheckboxField';
import Footnotes from '../../components/Footnotes/Footnotes';
import Button from '../../components/Button/Button';

import heroImg from '../../images/aboutNpc/hero.png';
import RadioField from '../../components/Form/Fields/RadioField/RadioField';
import { GtagHead } from '../../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Stay Connected | Loqtorzi™ Patient website</title>
      <meta name='description'content='Loqtorzi™ is committed to helping you on your treatment journey by providing education, support, and community for people living with NPC'/>
    </>
  )
}

export default function StayConnected() {
  return (
    <Layout>
    <Hero image={ { src: heroImg, alt:'Hero Stay Connected' } }>
      <Title>
        <h1>STAY CONNECTED</h1>
        <h2 className='h3 mt-[1.3rem] tablet:mt-[1.8rem]'>
          LOQTORZI™ is committed to helping you on your treatment journey by providing education, support, and community for people living with NPC
        </h2>
      </Title>
    </Hero>
    <GlobalWrapper className={''}>
      <div className='mt-[3rem] tablet:mt-[5rem] desktop:mt-[9rem]'>
        Learning about you helps us get you information that’s right for you. Complete the form below to ensure that we send you the information you need.
      </div>
      <Form className='mt-[3rem]' endpoint="/backend/submit.php" thankYouURL="/stay-in-touch/thank-you">
        <div className='tablet:flex tablet:gap-[3.9rem]'>
          <InputField
            className={ 'flex-1 mr-[1.2rem] tablet:mr-0' }
            name="firstName"
            label={ <span>First name*</span> }
            rules={{required: 'Enter your first name.'}}
          />

          <InputField
            className={ 'flex-1 mr-[1.2rem] tablet:mr-0' }
            name="lastName"
            label={ <span>Last name*</span> }
            rules={{required: 'Enter your last name.'}}
          />
        </div>

        <div className='tablet:flex tablet:gap-[3.9rem]'>
          <InputField
            className={ 'flex-1 mr-[1.2rem] tablet:mr-0' }
            name="email"
            label={ <span>Email address*</span> }
            rules={{
              required: 'Enter your email address.',
              pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
              },
            }}
          />

          <InputField
            className={ 'flex-1 mr-[1.2rem] tablet:mr-0' }
            name="phone"
            type='tel'
            label={ <span>Phone number</span> }
            rules={{
              validate: (value) => {
                if(value) {
                  let numbers = value.replace(/^\D+/g, '');
                  return !(numbers.length > 11 || numbers.length < 10) || 'Enter a valid phone number.';
                } else {
                  return true;
                }
              }
            }}
          />
        </div>

        <InputField
            className={ 'flex-1 mr-[1.2rem] tablet:mr-0' }
          name="mailing"
          label={ <span>Mailing address</span> }
        />

        <Footnotes className={'mt-[4.5rem]'} items={[
          {
            bullet:'*',
            text: <span>Required field.</span>
          }
        ]}/>

        <div className='mt-[2.4rem] text-blue-600'>
          <strong>Check the option that applies to you:</strong>
        </div>

        <RadioField className='mt-[.8rem] pl-[1.5rem] tablet:mt-[1.3rem]'
          name="HowHasNPCimpactedYou"
          items={[
            'I have been diagnosed with NPC that has spread to other parts of the body (metastatic) or has returned (recurrent) in nearby tissues (locally advanced)',
            'A loved one has been diagnosed with NPC that has spread to other parts of the body (metastatic) or has returned (recurrent) in nearby tissues (locally advanced)'
          ]}
          errorMsg={'Please select one to continue.'}
          required={ true }
        />

        <div className="mt-[2.7rem]">
            <div className="text-[1.6rem]">
                <strong>Uses and Disclosure of Personal Information</strong>
                <p className='mt-[1em]'>
                  I authorize Coherus Biosciences, Inc. and its contractors and business partners (&ldquo;Coherus&rdquo;) to use and/or disclose my personal information, including my personal health information, only for the following purposes:
                </p>

                <ul className="listDiscOverflow ml-[2rem] tablet:ml-[6rem] large:ml-[8rem]">
                    <li className='mb-[1rem]'>To enroll me in, and/or continue my participation in Coherus&rsquo; informational updates program;</li>
                    <li className='mb-[1rem]'>To provide me with informational and marketing materials relating to Coherus products and services, and/or a condition or treatment;</li>
                    <li className='mb-[1rem]'>To improve, develop, and evaluate products, services, materials and programs related to a condition or treatment.</li>
                    <li className='mb-[1rem]'>I understand that I may be contacted by mail, email, and/or telephone for any of these purposes. I further understand that the Coherus News Updates and additional information and marketing communications are optional and free services. I do not have to sign this authorization and this authorization in no way affects my right to obtain any medications. To obtain a copy of this authorization or to cancel at any time I can contact Coherus by calling 1-888-728-0419. The Coherus Privacy Policy can be found at http://www.reguesteasy.com/See3-7108. My consent is subject to all the terms and conditions stated in our privacy policy. You can make requests to know what personal information we have collected from you in the past 12 months or requests to delete your personal information via http://www.requesteasy.com/5ee3-7108 or 1-888-728-0419.</li>
                </ul>

                <div className='mt-[2.1rem]'><strong>LIMITATION OF LIABILITY</strong></div>
                <p>All the services, information, software, and other materials provided through the portal are provided on an &ldquo;as-is&rdquo; basis and without any express or implied warranties. While our database uses Blockchain security features, we do not guarantee the security of the database and connected portal(s) or the services or the prevention from loss of, alteration of, or improper access to, your account information or data. To the maximum extent permitted by law, under no circumstances shall Coherus, its officers, directors, employees, subsidiaries, or affiliated companies be liable for any direct, indirect, incidental, special, consequential, or punitive damages, such as, but not limited to, loss of revenue, loss of anticipated profits, goodwill, diminution of value, business interruption costs, or any other intangible losses arising out of damage from any security breach or any other security intrusion of personal information.</p>

                <p>By checking the &quot;I accept&quot; box, I am stating electronically that I have read and understood Coherus&#39; personal information terms (above in its full text), that I am at least 18 years old and authorize Coherus Biosciences, Inc., its contractors, and its business partners to use and disclose my Personal Information for the purposes described above.</p>

                <p>In addition to the above consent, I understand that by checking this box and signing, I consent to Coherus calling and texting me at any of the methods of communication I have provided with promotional communications relating to Coherus product and services and/or my condition or treatment. Coherus may use automatic dialing machines or artificial or prerecorded messages to contact me and may leave a voicemail or SMS/text message (standard text messaging rates may apply). I understand that I am not to provide this consent as a condition of purchasing any goods or services. I have the ability to reply STOP to cancel messages at anytime.</p>

                <p>This is a security feature to enhance your protection.<br />
                    </p>

            </div>
        </div>

        <CheckboxField
          name="accept"
          label="I accept"
          rules={{required: 'Please select the checkbox to continue.'}}
        />

        <div className='mt-[5.7rem] mb-[8.3rem] desktop:mb-[12.3rem] text-center'>
          <Button isSubmit={ true }>Submit</Button>
        </div>
      </Form>
    </GlobalWrapper>
    </Layout>
  );
}
