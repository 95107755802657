import React from 'react';
import { Controller } from 'react-hook-form';

import * as styles from './InputField.module.css';
import { error } from '../../Form.module.css';
import { useFormContext } from '../../Form';
import clsx from 'clsx';

export default function InputField ({ className, name, label, rules, type="text" }) {
  const { control, errors } = useFormContext();
  return (
    <div className={ clsx(className, 'mb-[2.5rem]') }>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({field}) => (
          <>
            <input
              {...field}
              type={ type }
              className={styles.input}
            />
            { <div className={ error }>{ errors[name]?.message }</div> || null }
          </>
        )}
      />
    </div>
  );
};
