import React from 'react';
import { useFormContext } from '../../Form';
import * as styles from './RadioField.module.css';
import { error } from '../../Form.module.css';
import clsx from 'clsx';

export default function RadioField ({ className, name, items, errorMsg, required=true }) {
  const { register, errors } = useFormContext();

  return (
    <section className={ clsx(className) }>
      {
        items.map((item, index) => 
          <div className={ styles.radioField } key={ index }>

            <label className={ styles.label } htmlFor={ item }>
              <input
                { ...register(name, { required: {
                  value: required,
                  message: errorMsg
                } }) }
                type="radio"
                name={ name }
                value={ item }
                className= { styles.input }
                id={ item }
              /><span className={ styles.text }>{ item }</span>
            </label>
          </div>
        )
      }
      {
        errors[name] && <div className={ clsx(error, 'mt-[1.4rem]') }>{ errorMsg }</div>
      }
    </section>
  );
};
