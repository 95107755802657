import React from 'react';
import { Controller } from 'react-hook-form';
import { useFormContext } from '../../Form'; // Make sure this import is correct
import * as styles from './CheckboxField.module.css'; // Make sure this import is correct
import { error as formError } from '../../Form.module.css';

const CheckboxField = ({name, label, rules}) => {
  const { control, errors } = useFormContext();

  return (
    <div className='mt-[2em]'>
      <p className={ formError }> { errors[name] && errors[name].message }</p>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        rules={rules}
        render={({ field }) => (
          <div className={ styles.wrapper }>
            <div className={ styles.container }>
              <input id={ name }
                { ...field }
                name={ name }
                type="checkbox"
                className={ styles.checkBox }
              />
            </div>
            <div className={ styles.text }>
              <label htmlFor={name}>
                { label }
              </label>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CheckboxField;
